$bg: #f2f2f2;

body {
  margin: 0;
  background: $bg;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
}

.site-wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  > div:nth-child(2) {
    position: relative;
    overflow: auto;
    flex: 1;
  }

  > div:nth-child(1) {
    height: 100px;
    transition: height 0.4s ease, box-shadow 0.4s ease;
    display: flex;
    position: relative;
    justify-content: right;
  }
}

.page {
  min-height: 100%;
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  background: $bg;
}

.menu {
  display: flex;
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: visible;
  height: 100%;
  align-items: center;
  margin-left: 20px;

  transition: opacity 0.4s ease;

  > a {
    text-decoration: none;
    color: #000;

    &.selected {
      color: #aaa;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.site-wrapper.scrolled {
  > div:nth-child(1) {
    height: 60px;
    box-shadow: 0 0 8px 1px rgba(#000, 0.14);
    z-index: 3;
  }

  .menu {
    opacity: 1;
    visibility: visible;
  }

  .title {
    margin-right: 20px;
    transform: translateX(0%);
    color: #aaa;
  }
}

.mountain-canvas,
.lightning-canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.mountain-canvas {
  opacity: 1;
  visibility: hidden;

  &:nth-of-type(2) {
    margin-top: 3px;
    transition-duration: 0.6s;
  }
  &:nth-of-type(3) {
    margin-top: 6px;
    transition-duration: 0.8s;
  }

  transition: margin-top 0.4s ease-out;

  &.ready {
    opacity: 1;
    visibility: visible;
    margin-top: 0px;
  }

  &:first-of-type {
    background-color: #111;
  }
}

.sketches {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;

  div {
    flex-basis: 25%;
    text-align: center;
  }

  canvas {
    width: 160px;
    height: 160px;
    cursor: pointer;
    margin-bottom: 60px;
  }
}

.footer {
  background-color: #555;
  height: 100px;
  padding-top: 30px;

  .links {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    img {
      width: 40px;
      height: 40px;
      background: #444;
      padding: 10px;
      border-radius: 2px;
    }
  }
}

.title {
  font-size: 22px;
  font-weight: bold;
  align-self: center;
  margin-right: 50%;
  transform: translateX(50%);
  color: #333;
  transition: margin-right 0.4s ease, transform 0.4s ease, color 0.4s ease;
}

.pen-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000, 0.5);

  .cp_embed_wrapper {
    max-width: 1200px;
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
  }
}
