body {
  background: #f2f2f2;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
}

.site-wrapper {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.site-wrapper > div:nth-child(2) {
  flex: 1;
  position: relative;
  overflow: auto;
}

.site-wrapper > div:nth-child(1) {
  height: 100px;
  justify-content: right;
  transition: height .4s, box-shadow .4s;
  display: flex;
  position: relative;
}

.page {
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: #f2f2f2;
  padding: 2rem;
}

.menu {
  opacity: 0;
  visibility: visible;
  height: 100%;
  align-items: center;
  margin-left: 20px;
  transition: opacity .4s;
  display: flex;
  position: absolute;
  left: 0;
}

.menu > a {
  color: #000;
  text-decoration: none;
}

.menu > a.selected {
  color: #aaa;
}

.menu > a:not(:last-child) {
  margin-right: 20px;
}

.site-wrapper.scrolled > div:nth-child(1) {
  height: 60px;
  z-index: 3;
  box-shadow: 0 0 8px 1px #00000024;
}

.site-wrapper.scrolled .menu {
  opacity: 1;
  visibility: visible;
}

.site-wrapper.scrolled .title {
  color: #aaa;
  margin-right: 20px;
  transform: translateX(0%);
}

.mountain-canvas, .lightning-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mountain-canvas {
  opacity: 1;
  visibility: hidden;
  transition: margin-top .4s ease-out;
}

.mountain-canvas:nth-of-type(2) {
  margin-top: 3px;
  transition-duration: .6s;
}

.mountain-canvas:nth-of-type(3) {
  margin-top: 6px;
  transition-duration: .8s;
}

.mountain-canvas.ready {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.mountain-canvas:first-of-type {
  background-color: #111;
}

.sketches {
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.sketches div {
  text-align: center;
  flex-basis: 25%;
}

.sketches canvas {
  width: 160px;
  height: 160px;
  cursor: pointer;
  margin-bottom: 60px;
}

.footer {
  height: 100px;
  background-color: #555;
  padding-top: 30px;
}

.footer .links {
  max-width: 500px;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
}

.footer .links img {
  width: 40px;
  height: 40px;
  background: #444;
  border-radius: 2px;
  padding: 10px;
}

.title {
  color: #333;
  align-self: center;
  margin-right: 50%;
  font-size: 22px;
  font-weight: bold;
  transition: margin-right .4s, transform .4s, color .4s;
  transform: translateX(50%);
}

.pen-modal {
  width: 100vw;
  height: 100vh;
  background: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.pen-modal .cp_embed_wrapper {
  max-width: 1200px;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
}

/*# sourceMappingURL=index.611a11bf.css.map */
